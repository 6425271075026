import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAbout, LazySvgoAngleRight, LazySvgoArrowBlue, LazySvgoArrowRightFull, LazySvgoArrowRightWhite, LazySvgoArrowRight, LazySvgoCalendarDark, LazySvgoCalendar, LazySvgoCheckCircle, LazySvgoCheck, LazySvgoChevronDownWhite, LazySvgoChevronDown, LazySvgoChevronLeft, LazySvgoCircle, LazySvgoClose, LazySvgoContact, LazySvgoDots, LazySvgoDown, LazySvgoError, LazySvgoGrataMarkerBestFriend, LazySvgoGrataMarker, LazySvgoGrataMobile, LazySvgoGrata, LazySvgoGratawhite, LazySvgoHeaderVector1, LazySvgoHeaderVector2, LazySvgoHeaderVector3, LazySvgoHeaderBurger, LazySvgoHeaderSearch, LazySvgoHeaderdots, LazySvgoIconsimple, LazySvgoImage, LazySvgoIndustry, LazySvgoInfoCircle, LazySvgoInsight, LazySvgoMail, LazySvgoMapMarker, LazySvgoMarker, LazySvgoMessage, LazySvgoMute, LazySvgoNewofficeCup, LazySvgoNewofficeUsers, LazySvgoNewofficeYears, LazySvgoOfficeLocation, LazySvgoPaperclip, LazySvgoPause, LazySvgoPdf, LazySvgoPhone, LazySvgoPlayVideo, LazySvgoPlay, LazySvgoPractice, LazySvgoPrint, LazySvgoQr, LazySvgoSearch, LazySvgoShare, LazySvgoSliderLink, LazySvgoSocialFacebook, LazySvgoSocialInstagram, LazySvgoSocialLinkedin, LazySvgoSocialTelegram, LazySvgoSocialTwitter, LazySvgoSocialYoutube, LazySvgoSpecializations, LazySvgoSuccess, LazySvgoTxt, LazySvgoUnmute, LazySvgoXml, LazySvgoZip } from '#components'
const lazyGlobalComponents = [
  ["SvgoAbout", LazySvgoAbout],
["SvgoAngleRight", LazySvgoAngleRight],
["SvgoArrowBlue", LazySvgoArrowBlue],
["SvgoArrowRightFull", LazySvgoArrowRightFull],
["SvgoArrowRightWhite", LazySvgoArrowRightWhite],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoCalendarDark", LazySvgoCalendarDark],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCheckCircle", LazySvgoCheckCircle],
["SvgoCheck", LazySvgoCheck],
["SvgoChevronDownWhite", LazySvgoChevronDownWhite],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoCircle", LazySvgoCircle],
["SvgoClose", LazySvgoClose],
["SvgoContact", LazySvgoContact],
["SvgoDots", LazySvgoDots],
["SvgoDown", LazySvgoDown],
["SvgoError", LazySvgoError],
["SvgoGrataMarkerBestFriend", LazySvgoGrataMarkerBestFriend],
["SvgoGrataMarker", LazySvgoGrataMarker],
["SvgoGrataMobile", LazySvgoGrataMobile],
["SvgoGrata", LazySvgoGrata],
["SvgoGratawhite", LazySvgoGratawhite],
["SvgoHeaderVector1", LazySvgoHeaderVector1],
["SvgoHeaderVector2", LazySvgoHeaderVector2],
["SvgoHeaderVector3", LazySvgoHeaderVector3],
["SvgoHeaderBurger", LazySvgoHeaderBurger],
["SvgoHeaderSearch", LazySvgoHeaderSearch],
["SvgoHeaderdots", LazySvgoHeaderdots],
["SvgoIconsimple", LazySvgoIconsimple],
["SvgoImage", LazySvgoImage],
["SvgoIndustry", LazySvgoIndustry],
["SvgoInfoCircle", LazySvgoInfoCircle],
["SvgoInsight", LazySvgoInsight],
["SvgoMail", LazySvgoMail],
["SvgoMapMarker", LazySvgoMapMarker],
["SvgoMarker", LazySvgoMarker],
["SvgoMessage", LazySvgoMessage],
["SvgoMute", LazySvgoMute],
["SvgoNewofficeCup", LazySvgoNewofficeCup],
["SvgoNewofficeUsers", LazySvgoNewofficeUsers],
["SvgoNewofficeYears", LazySvgoNewofficeYears],
["SvgoOfficeLocation", LazySvgoOfficeLocation],
["SvgoPaperclip", LazySvgoPaperclip],
["SvgoPause", LazySvgoPause],
["SvgoPdf", LazySvgoPdf],
["SvgoPhone", LazySvgoPhone],
["SvgoPlayVideo", LazySvgoPlayVideo],
["SvgoPlay", LazySvgoPlay],
["SvgoPractice", LazySvgoPractice],
["SvgoPrint", LazySvgoPrint],
["SvgoQr", LazySvgoQr],
["SvgoSearch", LazySvgoSearch],
["SvgoShare", LazySvgoShare],
["SvgoSliderLink", LazySvgoSliderLink],
["SvgoSocialFacebook", LazySvgoSocialFacebook],
["SvgoSocialInstagram", LazySvgoSocialInstagram],
["SvgoSocialLinkedin", LazySvgoSocialLinkedin],
["SvgoSocialTelegram", LazySvgoSocialTelegram],
["SvgoSocialTwitter", LazySvgoSocialTwitter],
["SvgoSocialYoutube", LazySvgoSocialYoutube],
["SvgoSpecializations", LazySvgoSpecializations],
["SvgoSuccess", LazySvgoSuccess],
["SvgoTxt", LazySvgoTxt],
["SvgoUnmute", LazySvgoUnmute],
["SvgoXml", LazySvgoXml],
["SvgoZip", LazySvgoZip],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
